import * as React from "react"
import PropTypes from "prop-types"
import SEO from "./seo"

const BotonFlotante = () => (
  <div
    className="relative container mx-auto"
    style={{ maxWidth: "100%", display: "block" }}
  >
    <SEO title="Slider" />

    <div className="carousel-inner relative w-full">
      <div className="containerBTON">
        <div className="circulo-bton-flotante">
          <a
            href="https://citasdelagente.com.co/"
            target="_blank"
            title="Chatea con nosotros las 24 horas Mediante Whatsapp!"
            alt="Chatea con nosotros las 24 horas Mediante Whatsapp!"
          >
            <div
              className="grid grid-cols-2 gap-4"
              style={{ top: "3rem", position: "relative", left: "1.6rem" }}
            >
              <div tabIndex="2">
                <div
                  style={{
                    left: "45%",
                    width: "3rem",
                    height: "3rem",
                    boxShadow: "0px 1px 10px rgba(0,0,0,0.4)",
                  }}
                  className="relative p-0 w-10 h-10 bg-white rounded-full hover:bg-gray-50 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none"
                >
                  <img
                    className="relative icono_boton_flotante_rueda"
                    style={{ left: "9px", top: "8px" }}
                    src={
                      process.env.GATSBY_API_URL +
                      "/uploads/Mi_cita_f554745b48.jpg"
                    }
                    formats={["auto", "webp", "avif"]}
                    width={35}
                    height={35}
                    alt="Boton Mi cita"
                    title="Boton Mi cita"
                  />
                </div>
              </div>
              <div
                style={{ position: "relative", top: "0.5rem", right: "4rem" }}
                className="text-poppins-regular green-primary"
              >
                Mi <br></br>Cita
              </div>
            </div>
          </a>

          <a
            // href="https://api.whatsapp.com/send?phone=573209949696"
            href="https://wa.me/573133774455"
            // href="https://api.whatsapp.com/send/?phone=573209949696&text=phone_number&app_absent=0"
            target="_blank"
            title="Chatea con nosotros las 24 horas Mediante Whatsapp!"
            alt="Chatea con nosotros las 24 horas Mediante Whatsapp!"
          >
            {/* <div grid grid-cols-2 gap-4> */}
            <div
              className="grid grid-cols-2 gap-4"
              // style={{ top: "5rem", position: "relative", right: "3rem" }}
              style={{ top: "5rem", position: "relative", right: "3rem" }}
            >
              <div tabIndex="2">
                <div
                  style={{
                    left: "45%",
                    width: "3rem",
                    height: "3rem",
                    boxShadow: "0px 1px 10px rgba(0,0,0,0.4)",
                  }}
                  className="relative p-0 w-10 h-10 bg-white rounded-full hover:bg-gray-50 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none"
                >
                  <img
                    className="relative icono_boton_flotante_rueda chatea_salud"
                    style={{ left: "6px", top: "6px" }}
                    src={
                      process.env.GATSBY_API_URL +
                      "/uploads/personaje_para_web_200x200_d6b447fc09.png"
                    }
                    formats={["auto", "webp", "avif"]}
                    width={42}
                    height={42}
                    alt="Chatea con salud"
                    title="Chatea con salud"
                  />
                </div>
              </div>
              <div
                style={{ position: "relative", top: "0.5rem", right: "4rem" }}
                className="text-poppins-regular green-primary"
              >
                Chatea <br></br>con Salud
              </div>
            </div>
            {/* </div> */}
          </a>
          <a
            href="/personas/linea-delagente/"
            target="_blank"
            title="Boton linea de atenión"
            alt="Boton linea de atenión"
          >
            <div className="bton_telefonos" style={{ cursor: "pointer" }}>
              <div
                className="bton_telefonos grid grid-cols-2 gap-4"
                // style={{ top: "7rem", position: "relative", left: "-3rem" }}
                style={{ top: "7rem", position: "relative", left: "-3rem" }}
              >
                <div tabIndex="2">
                  <div
                    style={{
                      left: "45%",
                      width: "3rem",
                      height: "3rem",
                      boxShadow: "0px 1px 10px rgba(0,0,0,0.4)",
                    }}
                    className="relative p-0 w-10 h-10 bg-white rounded-full hover:bg-gray-50 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none"
                  >
                    <img
                      className="relative icono_boton_flotante_rueda"
                      style={{ left: "9px", top: "9px" }}
                      src={
                        process.env.GATSBY_API_URL +
                        "/uploads/Linea_delagente_0577ddf1b0.jpg"
                      }
                      formats={["auto", "webp", "avif"]}
                      width={35}
                      height={35}
                      title="Boton linea de atenión"
                      alt="Boton linea de atenión"
                    />
                  </div>
                </div>

                <div
                  style={{ position: "relative", top: "0.5rem", right: "4rem" }}
                  className="text-poppins-regular green-primary"
                >
                  Línea <br></br>delagente
                </div>
              </div>
            </div>
          </a>

          <a
            href="https://www.epsdelagente.com.co/preguntale-a-salud"
            tabIndex="2"
          >
            {/* <a href="/detalleservicioenlinea/?buzon-de-solicitudes" tabIndex="2"></a> */}

            <div
              className="bton_pqrs grid grid-cols-2 gap-4"
              // style={{ top: "8.5rem", position: "relative", left: "1rem" }}
              style={{ top: "9rem", position: "relative", left: "0rem" }}
            >
              <div>
                <div
                  style={{
                    left: "45%",
                    width: "3rem",
                    height: "3rem",
                    boxShadow: "0px 1px 10px rgba(0,0,0,0.4)",
                    cursor: "pointer",
                  }}
                  className="relative p-0 w-10 h-10 bg-white rounded-full hover:bg-gray-50 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none"
                >
                  <img
                    className="relative icono_boton_flotante_rueda"
                    style={{ left: "9px", top: "9px" }}
                    src={
                      process.env.GATSBY_API_URL +
                      // "/uploads/Buzon_de_solicitudes_29d2e166d7.jpg"
                      "/uploads/IMG_1106_b6a3edee84.jpeg"
                    }
                    formats={["auto", "webp", "avif"]}
                    width={35}
                    height={35}
                    alt="Boton tus solicitudes"
                    title="Boton tus solicitudes"
                  />
                </div>
              </div>

              <div
                style={{ position: "relative", top: "0.5rem", right: "4rem" }}
                className="text-poppins-regular green-primary"
              >
                Pregúntale a <br></br> Salud
              </div>
            </div>
          </a>
        </div>
        {/* Rueda flotante de REDES SOCIALES */}

        <div className="btn-mas" tabIndex="2">
          <label htmlFor="btn-mas">
            <img
              // width={90}
              // height={90}
              className="w-20 h-20"
              src={
                process.env.GATSBY_API_URL + "/uploads/IMG_1197_72ac8e0b4c.jpg"
                // "/uploads/Aqui_te_ayudamos_bb71544a50.jpg"
              }
              formats={["auto", "webp", "avif"]}
              alt="Boton aqui te ayudamos"
              title="Boton aqui te ayudamos"
            />
          </label>
        </div>

        <div
          style={{ position: "relative", bottom: "0rem", right: "-1rem" }}
          tabIndex="2"
          className="btn-cruz"
        >
          <label
            style={{ cursor: "pointer", position: "relative", bottom: "-5px" }}
            className="fa fa-plus"
          ></label>
        </div>

        <div
          style={{ position: "relative", bottom: "4rem", right: "-1rem" }}
          className="btn-cruz-redes-sociales"
          tabIndex="2"
        >
          <label
            style={{ cursor: "pointer", position: "relative", bottom: "-5px" }}
            className="fa fa-plus"
          ></label>
        </div>

        <div
          style={{ position: "relative", bottom: "7rem", right: "-1rem" }}
          className="btn-cruz-telefonos"
          tabIndex="2"
        >
          <label
            style={{ cursor: "pointer", position: "relative", bottom: "-5px" }}
            className="fa fa-plus"
          ></label>
        </div>
      </div>
    </div>
    {/* INICIO MODAL */}
    <div
      id="info_popup"
      tabindex="-1"
      className="hidden flex fixed justify-center items-center overflow-y-auto overflow-x-hidden top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full"
    >
      <div className="relative p-4 w-full max-w-lg h-full md:h-auto">
        <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 md:p-8">
          <div className="flex justify-center mb-4 text-sm font-light text-gray-500 dark:text-gray-400">
            {/* <h3 className="mb-3 text-2xl font-bold text-gray-900 dark:text-white">
              Privacy info
            </h3> */}

            <img
              src={process.env.GATSBY_API_URL + "/uploads/logo_a1b429d0e0.PNG"}
              // classNameName="alineacion_imagen_home_desplegable"
              alt="Logo EPSdelagente"
              title="Logo EPSdelagente"
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              style={{ width: "400px" }}
            />
          </div>
          <div className="w-full flex justify-center color-texto-parrafo text-poppins-regular">
            <p className="text-center">
              Llamanos dandole click al siguiente boton
            </p>
          </div>

          <iframe
            src="https://clicktocall.trunkall.com:4443/"
            classNameName="inline mx-auto px-3 w-11/12 xl:w-8/12 px-0 lg:w-3/4 px-0 md:w-3/4 px-0"
          ></iframe>
          <div className="flex justify-center items-center pt-0 space-y-4 sm:flex sm:space-y-0">
            {/* <a
              href="#"
              className="font-medium text-primary-600 dark:text-primary-500 hover:underline"
            >
              Learn more about privacy
            </a> */}
            {/* <div className="items-center space-y-4 sm:space-x-4 sm:flex sm:space-y-0"> */}
            <button
              id="close_modal"
              type="button"
              className="py-2 px-4 w-full text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 sm:w-auto hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
            >
              Cerrar
            </button>
            {/* <button
                id="confirm-button"
                type="button"
                className="py-2 px-4 w-full text-sm font-medium text-center text-white rounded-lg bg-primary-700 sm:w-auto hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              >
                Confirm
              </button> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
    {/* FINAL MODAL */}
  </div>
)

BotonFlotante.propTypes = {
  siteTitle: PropTypes.string,
}

BotonFlotante.defaultProps = {
  siteTitle: ``,
}

export default BotonFlotante
